body, html {
  background: rgb(236, 236, 243) !important;
  font-family: Arial, sans-serif;
}

.app {
  max-width: 650px;
  margin: 0 auto;
}

.adm-button {
  &.adm-button-primary {
    color: #ffffff;
  }
  padding: 10px 12px;
}

:root:root {
  --adm-color-border: #979797;
  --adm-color-primary: #2F54EB;
  --adm-color-text: rgba(0, 0, 0, .87);
  --adm-color-light: rgba(0, 0, 0, .38);
}